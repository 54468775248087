import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { CardRow, TypeTitle, TypeDescription } from "./Card"
import Flavor from "./Flavor"

import { strawberryBanana, mango } from "../../data/smoothiesDataEn"

export default function EnglishSmoothies(props) {
  const { activeFlavor, setActiveFlavor, incomingFlavor } = props

  const images = useStaticQuery(graphql`
    query flavorsSmoothies {
      strawberryBanana: file(
        relativePath: {
          eq: "front-page-flavors/smoothie-strawberry-banana-can.png"
        }
      ) {
        childImageSharp {
          fixed(height: 300) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      mango: file(
        relativePath: { eq: "front-page-flavors/smoothie-mango-can.png" }
      ) {
        childImageSharp {
          fixed(height: 300) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return (
    <>
      <TypeTitle marginTop="5rem">Smoothies</TypeTitle>
      <TypeDescription>
        Made with real fruit, our smoothies are now plant-based and with low
        sugar added! Try our new Acai Berry Blast Smoothie!
      </TypeDescription>

      <CardRow>
        <Flavor
          data={strawberryBanana}
          fixed={images.strawberryBanana.childImageSharp.fixed}
          flavorIndex={7}
          activeFlavor={activeFlavor}
          setActiveFlavor={setActiveFlavor}
          incomingFlavor={incomingFlavor}
        />

        <Flavor
          data={mango}
          fixed={images.mango.childImageSharp.fixed}
          flavorIndex={8}
          activeFlavor={activeFlavor}
          setActiveFlavor={setActiveFlavor}
          incomingFlavor={incomingFlavor}
        />
      </CardRow>
    </>
  )
}
