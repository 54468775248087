export const strawberryBanana = {
  id: "smoothieStrawberryBanana",
  backgroundColor: "#8D1055",
  textColor: "#FFFFFF",
  name: "Strawberry Banana Smoothie",
  tagline: "Strawberry Banana Smoothie",
  description: false,
  ingredients:
    "Water, Strawberry purée (strawberry, sugar, potassium sorbate), Sugars (sugar, fructose, dextrose), Banana purée, Citric acid, Carob bean gum, Methyl cellulose, Guar gum, Natural flavours, Beetroot concentrate.",
  disclaimer:
    "May contain: Milk, Peanuts, Tree nuts, Soy, Wheat, Eggs from manufacturing and blending.",
  nutritionFacts: {
    servingSize: "Per 1 Container (237 mL/355 mL as prepared)",
    calories: "190",
    values: [
      {
        type: "primary",
        label: "Fat",
        value: "0.1g",
        percent: "1%",
      },
      {
        type: "secondary",
        label: "Saturated<br>+ Trans",
        value: "0g<br>0g",
        percent: "0%",
      },
      {
        type: "primary",
        label: "Carbohydrate",
        value: "46g",
        percent: "",
      },
      {
        type: "secondary",
        label: "Fibre",
        value: "2g",
        percent: "7%",
      },
      {
        type: "secondary",
        label: "Sugars",
        value: "44g",
        percent: "44%",
      },
      {
        type: "primary",
        label: "Protein",
        value: "0.5g",
        percent: "",
      },
      {
        type: "primary",
        label: "Cholesterol",
        value: "0mg",
        percent: "0%",
      },
      {
        type: "primary",
        label: "Sodium",
        value: "5mg",
        percent: "1%",
      },
      {
        type: "primary",
        label: "Potassium",
        value: "75mg",
        percent: "2%",
      },
      {
        type: "primary",
        label: "Calcium",
        value: "10mg",
        percent: "1%",
      },
      {
        type: "primary",
        label: "Iron",
        value: "0.4mg",
        percent: "2%",
      },
    ],
  },
}

export const mango = {
  id: "smoothieMango",
  backgroundColor: "#FFA832",
  textColor: "#004E7D",
  name: "Mango Smoothie",
  tagline: "Mango Smoothie",
  description: false,
  ingredients:
    "Water, Mango base (mango purée concentrate, water, pineapple juice concentrate, citric acid, natural flavour, pumpkin juice concentrate, annatto, potassium sorbate), Sugars (sugar, dextrose, fructose), Carob bean gum, Methyl cellulose, Guar gum, Natural flavour, Citric acid.",
  disclaimer:
    "May contain: Milk, Peanuts, Tree nuts, Soy, Wheat, Eggs from manufacturing and blending.",
  nutritionFacts: {
    servingSize: "Per 1 Container (237 mL/355 mL as prepared)",
    calories: "190",
    values: [
      {
        type: "primary",
        label: "Fat",
        value: "0.1g",
        percent: "1%",
      },
      {
        type: "secondary",
        label: "Saturated<br>+ Trans",
        value: "0g<br>0g",
        percent: "0%",
      },
      {
        type: "primary",
        label: "Carbohydrate",
        value: "46g",
        percent: "",
      },
      {
        type: "secondary",
        label: "Fibre",
        value: "0g",
        percent: "0%",
      },
      {
        type: "secondary",
        label: "Sugars",
        value: "44g",
        percent: "44%",
      },
      {
        type: "primary",
        label: "Protein",
        value: "0.3g",
        percent: "",
      },
      {
        type: "primary",
        label: "Cholesterol",
        value: "0mg",
        percent: "0%",
      },
      {
        type: "primary",
        label: "Sodium",
        value: "5mg",
        percent: "1%",
      },
      {
        type: "primary",
        label: "Potassium",
        value: "125mg",
        percent: "3%",
      },
      {
        type: "primary",
        label: "Calcium",
        value: "10mg",
        percent: "1%",
      },
      {
        type: "primary",
        label: "Iron",
        value: "0.1mg",
        percent: "1%",
      },
    ],
  },
}
